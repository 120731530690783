export enum EnumColumnInfo {
  MEMBER_ID = 'Member ID',
  MEMBER_ID_VALUE = 'memberId',
  FIRST_NAME = 'First Name',
  FIRST_NAME_VALUE = 'memberFirstName',
  LAST_NAME = 'Last Name',
  LAST_NAME_VALUE = 'memberLastName',
  DATE_OF_BIRTH = 'Date of Birth',
  DATE_OF_BIRTH_VALUE = 'dob',
  POLICY_NUMBER = 'Policy Number',
  POLICY_NUMBER_VALUE = 'policyNumber',
  SOURCE_CODE = 'Source Code',
  SOURCE_CODE_VALUE = 'sourceCode',
  GOVERNMENT_CODE = 'Government Code',
  GOVERNMENT_CODE_VALUE = 'governmentCode',
  MEMBER_STATE = 'Member State',
  MEMBER_STATE_VALUE = 'memberState',
  START_DATE_OF_SERVICE = 'Start Date of Service',
  START_DATE_OF_SERVICE_VALUE = 'startDateOfService',
  END_DATE_OF_SERVICE = 'End Date of Service',
  END_DATE_OF_SERVICE_VALUE = 'endDateOfService',
  POLICY_EFFECTIVE_DATE = 'Policy Effective Date',
  POLICY_EFFECTIVE_DATE_VALUE = 'policyEffectiveDate',
  POLICY_TERMINATION_DATE = 'Policy Termination Date',
  POLICY_TERMINATION_DATE_VALUE = 'policyTerminationDate',
  REJECT_DESCRIPTION = 'Reject Description',
  REJECT_CODE_LABEL = 'Reject Code',
  REJECT_CODE = 'rejectCode',
  REJECT_REASON_MODAL_TITLE = 'Reject Reason',
  REJECT_REASON = 'rejectReason',
  JOB_ID = 'Job ID',
  JOB_ID_VALUE = 'jobId',
  FILE_NAME = 'File Name',
  FILE_NAME_VALUE = 'filename',
  RECEIVED_DATE_AND_TIME = 'Received Date and Time',
  CREATED_TIMESTAMP_VALUE = 'createdTimestamp',
  TARGET_DATE_AND_TIME = 'Target Date and Time',
  TARGET_DATE_AND_TIME_VALUE = 'targetDateTimestamp',
  ASSIGNED_TO = 'Assigned To',
  ASSIGNED_TO_VALUE = 'assignedTo',
  STATUS = 'Status',
  FALLOUT_STATUS_VALUE = 'falloutStatus',
  DEAR_RECEIVED_DATE_AND_TIME = 'DEAR Received Date and Time',
  JOB_START_TIMESTAMP_VALUE = 'jobStartTimestamp',
  SUBMITTER = 'Submitter',
  VENDOR_NAME_VALUE = 'vendorName',
}

// import localStorage from 'react-secure-storage';
type FlnData = {
  [key: string]: string | number | boolean | null;
};
export const getFlnStorage = (jobId: string): FlnData => {
  const storedFlnData = localStorage.getItem(`${jobId}`);
  return storedFlnData ? JSON.parse(storedFlnData) : {};
};

export const setFlnStorage = (
  jobId: string,
  key: string,
  value: string | number | boolean | null
): void => {
  const existingflnData = getFlnStorage(jobId);
  const newData = { ...existingflnData, [key]: value };
  // flnData[key] = value;
  localStorage.setItem(`${jobId}`, JSON.stringify(newData));
};
export function removeFromLocalStorage(jobId: string): void {
  const storageKey = `${jobId}`;
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  } else {
    console.log(`Item with key ${storageKey} does not exist in localStorage`);
  }
}

import React, { createContext, ReactNode, useState } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';

type SSOAuthState = {
  isAuthenticated: boolean;
  initialSignIn: boolean;
  access_token: string;
  refresh_token: string;
  expires_on: Date;
  username: string;
  title: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
  department: string;
  division: string;
  msad_groups: string[];
  isAdmin: boolean;
  isSupervisor: boolean;
};

type SSOAuthContextState = {
  ssoAuth: SSOAuthState;
  setSSOAuth: React.Dispatch<React.SetStateAction<SSOAuthState>>;
};

const SSOAuthContext = createContext<SSOAuthContextState | undefined>(
  undefined
);

const SSOAuthProvider = ({ children }: { children: ReactNode }) => {
  const [ssoAuth, setSSOAuth] = useState<SSOAuthState>({
    isAuthenticated: false,
    initialSignIn: true,
    access_token: '',
    refresh_token: '',
    expires_on: dayjs('0000-00-00T00:00:00.000Z'),
    username: '',
    title: '',
    firstName: '',
    lastName: '',
    initials: '',
    email: '',
    department: '',
    division: '',
    msad_groups: [],
    isAdmin: false,
    isSupervisor: false,
  });

  return (
    <SSOAuthContext.Provider value={{ ssoAuth, setSSOAuth }}>
      {children}
    </SSOAuthContext.Provider>
  );
};

export { SSOAuthContext, SSOAuthProvider };

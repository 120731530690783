export enum EnumErrorHandlingInfo {
  ERROR = 'Error',
  ERROR_SMALL = 'error',
  API_FAILURE = 'Request taking longer than expected. Please refresh or try again later.',
  UNABLE_TO_DOWNLOAD = 'Unable to access file. Please try again later.',
  INTERNAL_SERVER_ERROR_UPDATE = 'An error occured while processing your request. Contact support team.',
  INTERNAL_SERVER_ERROR = 'Unable to fetch required details. Please try again later.',
  REJECT_REASON_ERROR = 'Enter Reject Reason',
  REJECT_DESCRIPTION_ERROR = 'Enter Reject Description',
  REJECT_CODE_ERROR = 'Enter Reject Code',
  SELECT_MEMBER_ID = 'Please select a member before submitting.',
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
  ERR_NETWORK = 'ERR_NETWORK',
  ERR_TIMEOUT = 'ERR_TIMEOUT',
}

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SubNavigationMenu } from '@abyss/web/ui/SubNavigationMenu';

export const StyledDrawer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  // Override Background Tint//
  const CustomDrawer = styled(Drawer, {
    '&.abyss-modal-overlay': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  });
  const DrawerContent = () => {
    const navItemsBase = [
      {
        before: <IconMaterial icon="home" variant="outlined" />,
        title: 'Home',
        href: `/internal/home`,
        onClick: () => {
          setIsMenuOpen(false);
        },
      },
    ];

    return (
      <React.Fragment>
        <SubNavigationMenu
          navItems={navItemsBase}
          position="left"
          variant="sideBarMenu"
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <CustomDrawer
        isOpen={isMenuOpen}
        onClose={() => {
          return setIsMenuOpen(false);
        }}
        position="left"
      >
        <Drawer.Section>
          <DrawerContent />
        </Drawer.Section>
      </CustomDrawer>
      <Button
        rounded
        size="$lg"
        onClick={() => {
          return setIsMenuOpen(true);
        }}
      >
        <IconMaterial
          title="menu"
          isScreenReadable
          icon="menu"
          color="$primary2"
        />
      </Button>
    </React.Fragment>
  );
};

import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { DataTable } from '@abyss/web/ui/DataTable';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useJobIDQuery, useDetailDownloadQuery } from '@src/api/queries';
import { getFlnStorage, setFlnStorage } from '@src/Utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Alert } from '@abyss/web/ui/Alert';
import { AxiosError } from 'axios';
import {
  EnumColumnInfo,
  EnumErrorHandlingInfo,
  EnumCommonInfo,
} from '@src/enums';

type MemberInfoProps = {
  sendDataToParent: (data: {
    falloutStatus: string | undefined;
    recordId: string | null;
  }) => void;
  fileName: string;
  setJobError: (isJobError: boolean) => void;
};

type Row = {
  index: number;
  isSelected: boolean;
  original: {
    recordId: string | null;
  };
};

const MemberInfo: React.FC<MemberInfoProps> = ({
  sendDataToParent,
  fileName,
  setJobError,
}) => {
  const [showRowIndex, setShowRowIndex] = useState<number | null>(null);
  const { getRouteParams } = useRouter();
  const { jobId } = getRouteParams();
  const [clicked, setClicked] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [recordId, setRecordId] = useState<string | null>(null);
  const {
    data: jobIDResult,
    isLoading,
    isError: isJobError,
    error: jobError,
  } = useJobIDQuery(jobId);
  const {
    data: download,
    isError: isDownloadError,
    error: downloadError,
  } = useDetailDownloadQuery(jobId, fileName);

  const columns = React.useMemo(() => {
    return [
      {
        Header: EnumColumnInfo.MEMBER_ID,
        accessor: EnumColumnInfo.MEMBER_ID_VALUE,
      },
      {
        Header: EnumColumnInfo.FIRST_NAME,
        accessor: EnumColumnInfo.FIRST_NAME_VALUE,
      },
      {
        Header: EnumColumnInfo.LAST_NAME,
        accessor: EnumColumnInfo.LAST_NAME_VALUE,
      },
      {
        Header: EnumColumnInfo.DATE_OF_BIRTH,
        accessor: EnumColumnInfo.DATE_OF_BIRTH_VALUE,
      },
      {
        Header: EnumColumnInfo.POLICY_NUMBER,
        accessor: EnumColumnInfo.POLICY_NUMBER_VALUE,
      },
      {
        Header: EnumColumnInfo.SOURCE_CODE,
        accessor: EnumColumnInfo.SOURCE_CODE_VALUE,
      },
      {
        Header: EnumColumnInfo.GOVERNMENT_CODE,
        accessor: EnumColumnInfo.GOVERNMENT_CODE_VALUE,
      },
      {
        Header: EnumColumnInfo.MEMBER_STATE,
        accessor: EnumColumnInfo.MEMBER_STATE_VALUE,
      },
      {
        Header: EnumColumnInfo.START_DATE_OF_SERVICE,
        accessor: EnumColumnInfo.START_DATE_OF_SERVICE_VALUE,
      },
      {
        Header: EnumColumnInfo.END_DATE_OF_SERVICE,
        accessor: EnumColumnInfo.END_DATE_OF_SERVICE_VALUE,
      },
      {
        Header: EnumColumnInfo.POLICY_EFFECTIVE_DATE,
        accessor: EnumColumnInfo.POLICY_EFFECTIVE_DATE_VALUE,
      },
      {
        Header: EnumColumnInfo.POLICY_TERMINATION_DATE,
        accessor: EnumColumnInfo.POLICY_TERMINATION_DATE_VALUE,
        width: 180,
      },
    ];
  }, []);

  const handleRowSelection = useCallback(
    (row: Row) => {
      if (clicked && showRowIndex !== row.index) {
        return false;
      }
      if (row?.isSelected) {
        const recordId = row?.original?.recordId;
        if (jobId) {
          setFlnStorage(jobId, EnumCommonInfo.SELECT_INDEX, row.index);
        }
        setShowRowIndex(row.index);
        if (recordId) {
          setRecordId(recordId);
        }
      }
      return true;
    },
    [showRowIndex, jobId, clicked, sendDataToParent]
  );

  const customDownloadOption = {
    title: 'Download Submissions',
    icon: <IconSymbol icon={EnumCommonInfo.DOWNLOAD_DONE_ICON} />,

    onClick: () => {
      if (download) {
        const link = document.createElement('a');
        link.href = download;
        link.download = EnumCommonInfo.CUSTOM_FULL_CSV;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        if (isDownloadError) {
          setIsErrorVisible(true);
        }
      }
    },
  };

  const dataTableProps = useDataTable({
    initialColumns: columns,
    singleSelection: (row: Row) => handleRowSelection(row),
    showPagination: true,
    showDownloadButton: true,
    downloadButtonConfig: {
      fullCsvFileName: `${
        jobIDResult?.data.length > 0 ? jobIDResult?.data[0]?.jobId : ''
      }_full.csv`,
      removeFull: isJobError ? true : false,
      removeFiltered: true,
      custom: customDownloadOption,
    },
  });

  useEffect(() => {
    if (jobIDResult) {
      dataTableProps.setData(jobIDResult?.data);
      sendDataToParent({
        falloutStatus: jobIDResult?.data[0]?.falloutJob?.falloutStatus,
        recordId: recordId,
      });
    }
  }, [jobIDResult, dataTableProps, sendDataToParent]);

  useEffect(() => {
    if (showRowIndex !== null && jobId) {
      setFlnStorage(jobId, 'fln', jobId);
    }
  }, [jobId, showRowIndex]);

  useEffect(() => {
    if (jobId) {
      const savedflndata = getFlnStorage(jobId);
      const savedIndex = savedflndata?.selectedIndex;
      const holdState = savedflndata?.holdState;

      if ((savedIndex !== null && savedIndex !== undefined) || holdState) {
        const rowIndex = parseInt(savedIndex as string, 10);
        dataTableProps?.rows?.[rowIndex]?.toggleRowSelected(true);

        setShowRowIndex(rowIndex);
        if (holdState === EnumCommonInfo.HOLD) {
          setClicked(true);
        } else {
          setClicked(false);
        }
      }
    }
  }, [dataTableProps, jobId, clicked]);

  return (
    <React.Fragment>
      <Alert
        title={EnumErrorHandlingInfo.ERROR}
        variant={EnumErrorHandlingInfo.ERROR_SMALL}
        isVisible={isJobError || isErrorVisible}
      >
        {((jobError || downloadError) as AxiosError)?.code ===
        EnumErrorHandlingInfo.ERR_NETWORK
          ? isDownloadError
            ? EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.API_FAILURE
            : EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.API_FAILURE
          : isDownloadError
            ? EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.UNABLE_TO_DOWNLOAD
            : EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR}
      </Alert>
      <LoadingOverlay
        loadingTitle={EnumCommonInfo.PLEASE_WAIT}
        loadingMessage={EnumCommonInfo.RETRIEVING_INFORMATION}
        statusTitle={EnumCommonInfo.STATUS_UPDATE}
        statusMessage={EnumCommonInfo.SAMPLE_MESSAGE}
        isDismissable
        ariaLoadingLabel={EnumCommonInfo.LOADING_LABEL}
        isLoading={isLoading}
      >
        <DataTable
          tableState={dataTableProps}
          title=""
          data-testid="tableID"
          css={{
            'abyss-data-table-header': { display: 'none', paddingTop: '20px' },
            'abyss-data-table-subheader-lower-container': { order: 1 },
            'abyss-data-table-pagination-bottom-root': {
              display: 'none',
            },
            'abyss-data-table-pagination-top-root': {
              display: 'contents',
            },
            '.abyss-table-styled-header-col-2 .abyss-table-header-data': {
              color: '#003C71',
            },
            '.abyss-table-styled-header-col-3 .abyss-table-header-data': {
              color: '#003C71',
            },
            '.abyss-table-styled-header-col-4 .abyss-table-header-data': {
              color: '#003C71',
            },
            '.abyss-table-styled-header-col-6 .abyss-table-header-data': {
              color: '#003C71',
            },
          }}
        />
      </LoadingOverlay>
    </React.Fragment>
  );
};

export { MemberInfo };

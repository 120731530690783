export enum EnumCommonInfo {
  SAMPLE_MESSAGE = 'Sample message...',
  DOWNLOAD_PDF = 'Download PDF',
  ON_HOLD = 'ON_HOLD',
  REJECTED = 'REJECTED',
  UN_HOLD = 'UN_HOLD',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECT_CODE_PLACEHOLDER = 'Select Reject Code',
  REJECT_REASON_PLACEHOLDER = 'Select Reject Reason',
  REJECT_DESCRIPTION_PLACEHOLDER = 'Enter Reject Description',
  NO_RESULTS = 'No results',
  PLEASE_WAIT = 'Please Wait',
  RETRIEVING_INFORMATION = 'Retrieving Information.',
  STATUS_UPDATE = 'Status update',
  SLA_RISK = 'SLA_RISK',
  DELAYED = 'DELAYED',
  REJECT_DESCRIPTION = 'rejectDescription',
  COLON = ':',
  REQUIRED = 'required',
  R001_MNF = 'R001 - MNF',
  DATE_NOT_WITHIN_RANGE = 'Date of service is not within the Effective Date Range',
  SOURCE_SYSTEM_NOT_EQUAL_CDB = 'Member Found, but Source System NOT EQUAL to CDB',
  ENROLLEE_SOURCE_CODE_NOT_IN = 'Enrollee Source Code is NOT IN (CS, AP, CR, CO)',
  COVERAGE_TYPE_NOT_EQUAL_M = 'Member Found, but Coverage Type NOT EQUAL to M',
  BOOLEAN = 'boolean',
  RELEASED = 'released',
  EDIT = 'Edit',
  DOWNLOAD_DONE_ICON = 'download_done',
  CUSTOM_FULL_CSV = 'customFull.csv',
  HOLD = 'Hold',
  LOADING_LABEL = 'Example loading aria label',
  SELECT_INDEX = 'selectedIndex',
  ENTER_ASSIGNEE_DETAILS = 'Enter Assignee Details',
  REVIEW = 'REVIEW',
  NOT_STARTED = 'NOT_STARTED',
  ASSIGNED = 'ASSIGNED',
  REASSIGNED = 'REASSIGNED',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ENTER_ASSIGN_DETAILS = 'Enter Assign Details',
  ENTER_REASSIGN_DETAILS = 'Enter Reassign Details',
  ASSIGN = 'Assign',
  REASSIGN = 'Reassign',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
  TIME_DURATION = 'Time Duration',
  FALLOUT_QUEUE_DATA_CSV = 'Fallout_Queue_Data.csv',
  TIME_DURATION_SMALL = 'timeDuration',
  DATE = 'date',
  HOME = 'Home',
  FALLOUT_QUEUE = 'Fallout Queue',
  SEARCH_RESULTS = 'Search Results',
  MODAL_FORM = 'modal-form',
  ENTER_FIRST_NAME = 'Enter a First Name',
  ENTER_LAST_NAME = 'Enter a LastName',
  ENTER_A_LAST_NAME = 'Enter a Last Name',
  RESET = 'reset',
  OUTLINE = 'outline',
  SUBMIT = 'submit',
}

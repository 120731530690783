import {
  DataTableIndividualAction,
  useDataTable,
} from '@abyss/web/hooks/useDataTable';

import { useForm } from '@abyss/web/hooks/useForm';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@abyss/web/ui/Button/v1/Button';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Modal } from '@abyss/web/ui/Modal/Modal';
import { Router } from '@abyss/web/ui/Router';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useActionMutation } from '@src/api/mutation';
import { useFalloutQuery } from '@src/api/queries';
import { useSSOAuth } from '@src/hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState, CSSProperties } from 'react';
import { AuditTrail } from '../AuditTrail';
import { Timer } from './Timer';
import { Alert } from '@abyss/web/ui/Alert';
import {
  EnumColumnInfo,
  EnumCommonInfo,
  EnumErrorHandlingInfo,
} from '@src/enums';
import { AxiosError } from 'axios';

type JobIDResult = {
  jobId: string;
  filename: string;
  createdTimestamp: string;
  targetDateTimestamp: string;
  assignedTo: string;
  falloutStatus: string;
};

type Row = {
  original: JobIDResult;
};

// type CellActions = {
//   row: Row;
//   deleteRow: (row: Row) => void;
//   modifyRow: (row: Row, updatedData: Partial<Row>) => void;
//   clearCache: () => void;
// };

type ReassignFormData = {
  firstName: string;
  lastName: string;
};

export const Home = () => {
  const alertStyles: CSSProperties = {
    paddingBottom: '10px',
  };
  const { navigate } = useRouter();
  const query = useQueryClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { ssoAuth } = useSSOAuth();
  const {
    data: jobIDResults,
    isLoading,
    isError: isError,
    error: error,
  } = useFalloutQuery();
  const [modalTitle, setModalTitle] = useState<string>(
    EnumCommonInfo.ENTER_ASSIGNEE_DETAILS
  );
  const {
    mutateAsync: actionMutation,
    isError: isUpdateError,
    error: updateError,
  } = useActionMutation();
  const review = EnumCommonInfo.REVIEW;
  const inProgress = EnumCommonInfo.IN_PROGRESS;
  const notStarted = EnumCommonInfo.NOT_STARTED;
  const assigned = EnumCommonInfo.ASSIGNED;
  const reassigned = EnumCommonInfo.REASSIGNED;
  const modal = useOverlay('modal-form');
  const reassigneForm = useForm<ReassignFormData>();
  const firstName = reassigneForm.watch(EnumCommonInfo.FIRST_NAME);
  const lastName = reassigneForm.watch(EnumCommonInfo.LAST_NAME);
  const [reassignJobId, setReassignJobId] = useState<string>('');
  const [reassignStatus, setReassignStatus] = useState<string>('');
  const convertToLocaleString = (value) => {
    if (value === '' || value === null || value === undefined) {
      return null;
    }
    const newDateTime = new Date(value).toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    return newDateTime;
  };
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);

  // const handleReviewClick = (row: Row) => {
  //   navigate(`/internal/detail/${row?.original?.jobId}`, {
  //     state: { filename: row?.original?.filename },
  //   });
  // };

  const actions: DataTableIndividualAction[] = [
    {
      onClick: ({ row }) => {
        try {
          setReassignJobId(row?.original?.jobId);
          setReassignStatus(row?.original?.falloutStatus);
          {
            row?.original?.falloutStatus === notStarted &&
            !row?.original?.assignedTo
              ? setModalTitle(EnumCommonInfo.ENTER_ASSIGN_DETAILS)
              : setModalTitle(EnumCommonInfo.ENTER_REASSIGN_DETAILS);
          }

          if (modal) {
            modal.open();
          }
        } catch (error) {
          setIsErrorVisible(true);
        }
      },
      checkDisabled: (row) => {
        return (
          row?.original?.falloutStatus !== notStarted ||
          (row?.original?.assignedTo &&
            row?.original?.falloutStatus === notStarted)
        );
      },
      label: EnumCommonInfo.ASSIGN,
    },

    {
      onClick: async ({ row }) => {
        if (
          !row?.original?.assignedTo ||
          row?.original?.falloutStatus === EnumCommonInfo.REASSIGNED ||
          row?.original?.falloutStatus === EnumCommonInfo.NOT_STARTED ||
          ((row?.original?.falloutStatus === EnumCommonInfo.SLA_RISK ||
            row?.original?.falloutStatus === EnumCommonInfo.DELAYED) &&
            row?.original?.userAction === EnumCommonInfo.REASSIGNED)
        ) {
          try {
            const response = await actionMutation({
              // falloutStatus: inProgress,

              falloutStatus:
                row?.original?.falloutStatus === 'SLA_RISK' ||
                row?.original?.falloutStatus === 'DELAYED'
                  ? row?.original?.falloutStatus
                  : inProgress,

              jobId: row?.original?.jobId,
              assignTo:
                (row?.original?.falloutStatus === notStarted &&
                  row?.original?.assignedTo) ||
                row?.original?.userAction === reassigned
                  ? (row?.original?.assignedTo ?? null)
                  : `${ssoAuth.lastName} , ${ssoAuth.firstName}`,
              userAction: review,
            });

            if (response.status === 200) {
              navigate(`/internal/detail/${row?.original?.jobId}`, {
                state: { filename: row?.original?.filename },
              });
            }
          } catch (error) {
            setIsErrorVisible(true);
          }
        } else {
          navigate(`/internal/detail/${row?.original?.jobId}`, {
            state: { filename: row?.original?.filename },
          });
        }
      },
      //disable if status is notStarted
      // checkDisabled: (row: Row) => {
      //   return (
      //     row?.original?.falloutStatus === notStarted &&
      //     !row?.original?.assignedTo
      //   );
      // },
      label: 'Review',
    },
    {
      onClick: ({ row }) => {
        try {
          setReassignJobId(row?.original?.jobId);
          setReassignStatus(row?.original?.falloutStatus);
          {
            row?.original?.falloutStatus === notStarted &&
            !row?.original?.assignedTo
              ? setModalTitle(EnumCommonInfo.ENTER_ASSIGN_DETAILS)
              : setModalTitle(EnumCommonInfo.ENTER_REASSIGN_DETAILS);
          }

          if (modal) {
            modal.open();
          }
        } catch (error) {
          setIsErrorVisible(true);
        }
        if (modal) {
          modal.open();
        }
      },
      //disable if status is notStarted
      checkDisabled: (row: Row) => {
        return (
          row?.original?.falloutStatus === notStarted &&
          !row?.original?.assignedTo
        );
      },
      label: EnumCommonInfo.REASSIGN,
    },
  ];

  const columns = React.useMemo(() => {
    return [
      {
        Header: EnumColumnInfo.JOB_ID,
        accessor: EnumColumnInfo.JOB_ID_VALUE,
        maxWidth: 80,
        hiddenDefaultFilters: [
          'greater',
          'greater-equal',
          'less',
          'less-equal',
        ],
      },
      {
        Header: EnumColumnInfo.FILE_NAME,
        accessor: EnumColumnInfo.FILE_NAME_VALUE,
        maxWidth: 150,
      },
      {
        Header: EnumColumnInfo.DEAR_RECEIVED_DATE_AND_TIME,
        accessor: EnumColumnInfo.JOB_START_TIMESTAMP_VALUE,
        minWidth: 260,
        Cell: ({ value }: { value: string }) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              {convertToLocaleString(value)}
            </span>
          );
        },
      },
      {
        Header: EnumColumnInfo.SUBMITTER,
        accessor: EnumColumnInfo.VENDOR_NAME_VALUE,
        maxWidth: 150,
      },

      {
        Header: EnumColumnInfo.RECEIVED_DATE_AND_TIME,
        accessor: EnumColumnInfo.CREATED_TIMESTAMP_VALUE,
        minWidth: 220,
        Cell: ({ value }: { value: string }) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              {convertToLocaleString(value)}
            </span>
          );
        },
      },
      {
        Header: EnumColumnInfo.TARGET_DATE_AND_TIME,
        accessor: EnumColumnInfo.TARGET_DATE_AND_TIME_VALUE,
        minWidth: 200,
        Cell: ({ value }: { value: string }) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              {convertToLocaleString(value)}
            </span>
          );
        },
      },
      {
        Header: EnumColumnInfo.ASSIGNED_TO,
        accessor: EnumColumnInfo.ASSIGNED_TO_VALUE,
        maxWidth: 150,
      },
      {
        Header: EnumColumnInfo.STATUS,
        accessor: EnumColumnInfo.FALLOUT_STATUS_VALUE,
        maxWidth: 150,
        Cell: ({ value }: { value: string }) => {
          let badgeValues: {
            badgeLabel: string;
            variant:
              | EnumCommonInfo.SUCCESS
              | EnumCommonInfo.WARNING
              | EnumErrorHandlingInfo.ERROR_SMALL
              | EnumCommonInfo.INFO
              | EnumCommonInfo.NEUTRAL;
            outline: boolean;
          };

          switch (value) {
            case EnumCommonInfo.ON_HOLD:
              badgeValues = {
                badgeLabel: value,
                variant: EnumCommonInfo.INFO,
                outline: true,
              };
              break;
            case EnumCommonInfo.IN_PROGRESS:
              badgeValues = {
                badgeLabel: EnumCommonInfo.IN_PROGRESS,
                variant: EnumCommonInfo.SUCCESS,
                outline: true,
              };
              break;
            case EnumCommonInfo.REASSIGNED:
              badgeValues = {
                badgeLabel: value,
                variant: EnumCommonInfo.SUCCESS,
                outline: true,
              };
              break;
            case EnumCommonInfo.SLA_RISK:
              badgeValues = {
                badgeLabel: value,
                variant: EnumCommonInfo.WARNING,
                outline: true,
              };
              break;
            case EnumCommonInfo.DELAYED:
              badgeValues = {
                badgeLabel: EnumCommonInfo.DELAYED,
                variant: EnumErrorHandlingInfo.ERROR_SMALL,
                outline: true,
              };
              break;
            default:
              badgeValues = {
                badgeLabel: EnumCommonInfo.NOT_STARTED,
                variant: EnumCommonInfo.NEUTRAL,
                outline: true,
              };
              break;
          }

          const { badgeLabel, variant } = badgeValues;

          return <Badge variant={variant}>{badgeLabel}</Badge>;
        },
      },
      {
        Header: EnumCommonInfo.TIME_DURATION,
        // maxWidth: 100,
        Cell: ({ row }: { row: Row }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Timer
                falloutStatus={row?.original?.falloutStatus}
                createdTimestamp={row?.original?.createdTimestamp}
              />
            </div>
          );
        },
      },
    ];
  }, []);

  const tableData = useDataTable({
    initialColumns: columns,
    // initialData: jobIDResults || [],
    noDataMessage: EnumCommonInfo.NO_RESULTS,
    showDownloadButton: isError ? false : true,
    removeCsvColumns: [EnumCommonInfo.TIME_DURATION],
    downloadButtonConfig: {
      fullCsvFileName: EnumCommonInfo.FALLOUT_QUEUE_DATA_CSV,
      removeFiltered: true,
    },
    individualActions: actions,
    pageSizeOptions: [5, 10, 25],
    pageSizeDefault: 10,
    removeFilterColumns: [EnumCommonInfo.TIME_DURATION_SMALL],
    highlightRowOnHover: true,
    showGlobalFilter: true,
    filterColumnTypes: {
      targetDateTime: {
        type: EnumCommonInfo.DATE,
      },
      receivedDateTime: {
        type: EnumCommonInfo.DATE,
      },
    },
  });

  const onReassignSubmit = async (data: ReassignFormData) => {
    const response = await actionMutation({
      falloutStatus:
        reassignStatus === 'SLA_RISK' || reassignStatus === 'DELAYED'
          ? reassignStatus
          : reassignStatus === notStarted
            ? notStarted
            : reassigned,
      jobId: reassignJobId,
      assignTo: `${lastName} , ${firstName}`,
      userAction: reassignStatus === notStarted ? assigned : reassigned,
    });
    if (response.status === 200) {
      reassigneForm.reset();
      if (modal) {
        modal.close();
      }
      // Set a timer to enable the "Review" button and change "Assign" to "Reassign" after 5 minutes
      setReassignStatus(reassigned);
      setModalTitle('Enter Reassign Details');
    }
  };
  const onReassignCancel = () => {
    reassigneForm.reset();
    if (modal) {
      modal.close();
    }
    setIsModalErrorVisible(false);
  };

  useEffect(() => {
    if (isError) {
      setIsErrorVisible(true);
    } else if (jobIDResults) {
      tableData.setData(jobIDResults);
    }
  }, [isError, jobIDResults]);

  return (
    <React.Fragment>
      <Router.MetaTags title={EnumCommonInfo.HOME} />
      <React.Fragment>
        <Card collapse header={EnumCommonInfo.FALLOUT_QUEUE}>
          <Card.Section
            css={{
              'abyss-card-section': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            }}
          >
            <Alert
              title={EnumErrorHandlingInfo.ERROR}
              variant={EnumErrorHandlingInfo.ERROR_SMALL}
              isVisible={isError || (isUpdateError && isErrorVisible)}
            >
              {EnumErrorHandlingInfo.ERROR +
                EnumCommonInfo.COLON +
                ' ' +
                EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR}
            </Alert>
            {/* {isPending ? ( */}
            <LoadingOverlay
              loadingTitle={EnumCommonInfo.PLEASE_WAIT}
              loadingMessage={EnumCommonInfo.RETRIEVING_INFORMATION}
              statusTitle={EnumCommonInfo.STATUS_UPDATE}
              statusMessage={EnumCommonInfo.SAMPLE_MESSAGE}
              isDismissable
              ariaLoadingLabel={EnumCommonInfo.LOADING_LABEL}
              isLoading={isLoading}
              // isOpen={isOpen}
              // onClose={handleClose}
            >
              {/* ) : ( */}
              <DataTable
                title={EnumCommonInfo.SEARCH_RESULTS}
                tableState={tableData}
                css={{
                  '&.abyss-data-table-scroll': {
                    order: 1,
                  },
                  'abyss-data-table-subheader-lower-container': {
                    order: 1,
                  },
                  'abyss-data-table-subheader-top-container': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '20px',
                  },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                    order: 2,
                    justifyContent: 'end',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />{' '}
            </LoadingOverlay>

            {/* )} */}
          </Card.Section>
        </Card>
        <React.Fragment>
          <AuditTrail />
        </React.Fragment>
      </React.Fragment>
      <React.Fragment>
        <FormProvider
          state={reassigneForm}
          onSubmit={onReassignSubmit}
          autoComplete="on"
        >
          <Modal
            title={modalTitle}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            model={EnumCommonInfo.MODAL_FORM}
          >
            <Modal.Section>
              <Alert
                title={EnumErrorHandlingInfo.ERROR}
                variant={EnumErrorHandlingInfo.ERROR_SMALL}
                isVisible={isUpdateError && isModalErrorVisible}
                css={{ 'abyss-alert-root': alertStyles }}
              >
                {EnumErrorHandlingInfo.ERROR +
                  EnumCommonInfo.COLON +
                  ' ' +
                  EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR_UPDATE}
              </Alert>
              <Grid>
                <>
                  <Grid.Col span={6}>
                    <TextInput
                      model={EnumCommonInfo.FIRST_NAME}
                      label={EnumColumnInfo.FIRST_NAME}
                      isClearable
                      validators={{
                        required:
                          EnumErrorHandlingInfo.ERROR +
                          EnumCommonInfo.COLON +
                          ' ' +
                          EnumCommonInfo.ENTER_FIRST_NAME,
                      }}
                      css={{
                        'abyss-select-input-root': {
                          padding: '20px',
                          marginBottom: '20px',
                        },
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      model={EnumCommonInfo.LAST_NAME}
                      label={EnumColumnInfo.LAST_NAME}
                      isClearable
                      errorMessage={EnumCommonInfo.ENTER_LAST_NAME}
                      validators={{
                        required:
                          EnumErrorHandlingInfo.ERROR +
                          EnumCommonInfo.COLON +
                          ' ' +
                          EnumCommonInfo.ENTER_A_LAST_NAME,
                      }}
                      css={{
                        'abyss-select-input-root': {
                          padding: '20px',
                        },
                      }}
                    />
                  </Grid.Col>
                </>
              </Grid>
              <Layout.Space />
              <Layout.Group alignLayout="right" style={{ marginTop: '0px' }}>
                <Button
                  type={EnumCommonInfo.RESET}
                  variant={EnumCommonInfo.OUTLINE}
                  onClick={onReassignCancel}
                >
                  Cancel
                </Button>
                <Button
                  type={EnumCommonInfo.SUBMIT}
                  onClick={() => {
                    reassigneForm.handleSubmit(onReassignSubmit)();
                    if (reassigneForm.formState.isValid && modal) {
                      modal.close();
                    }
                  }}
                >
                  Submit
                </Button>
              </Layout.Group>
            </Modal.Section>
          </Modal>
        </FormProvider>
      </React.Fragment>
    </React.Fragment>
  );
};

import { useQueries, useQuery } from '@tanstack/react-query';
import {
  getAuditTrailByJobID,
  getAuditTrailJobIDs,
  getByJobID,
  getJobIDs,
  getDetailDownloadJobID,
} from './apis';
import { useMemo } from 'react';
type DataTableData = {
  jobId: string;
  filename: string;
  createdTimestamp: string;
  targetDateTimestamp: string;
  assignedTo: string;
  falloutStatus: string;
};
type AuditDataTableData2 = {
  jobId: string;
  filename: string;
  userAction: string;
  falloutStatus: string;
  outcome: string;
  assignedTo: string;
  actionTimestamp: string;
  rejectedReasonType: string;
  rejectedReasonDescription: string;
  rejectedReasonCode: string;
};

type Outcome = {
  id: {
    jobId: string;
    recordId: number;
  };
  dob: string;
  jobId: string;
  memberId: string;
  recordId: number;
  createdBy: string;
  sourceCode: string;
  memberState: string;
  policyNumber: string;
  governmentCode: string;
  memberLastName: string;
  memberFirstName: string;
  createdTimestamp: number;
  endDateOfService: string;
  startDateOfService: string;
  policyEffectiveDate: string;
  policyTerminationDate: string;
};

type AuditDataTableData = {
  jobId: string;
  filename: string;
  createdTimestamp: string;
  completedTimestamp: string;
  timeDuration?: string;
  userAction: string;
  falloutStatus: string;
  outcome: Outcome | null;
  assignedTo: string;
  updatedBy?: string;
};
const useJobIDQuery = (jobID) => {
  return useQuery({
    // enabled: false,
    queryKey: ['jobID', jobID],
    queryFn: () => {
      return getByJobID(jobID);
    },
  });
};
const useFalloutQuery = () => {
  return useQuery<DataTableData[]>({
    queryKey: ['falloutData'],
    queryFn: getJobIDs,
    refetchIntervalInBackground: true,
    refetchInterval: 300000,
  });
};
const useAuditJobIDQuery = (jobID) => {
  return useQuery({
    queryKey: ['auditJobID', jobID],
    queryFn: () => {
      return getAuditTrailByJobID(jobID);
    },
  });
};
const useAuditQuery = () => {
  return useQuery<AuditDataTableData[]>({
    queryKey: ['auditData'],
    queryFn: getAuditTrailJobIDs,
  });
};

const useDetailDownloadQuery = (jobID, fileName) => {
  return useQuery({
    queryKey: ['detailDownload', jobID],
    queryFn: () => {
      return getDetailDownloadJobID(jobID, fileName);
    },
  });
};
type SubRow = {
  jobId: string;
  falloutStatus: string;
  userAction: string;
  assignedTo: string;
  outcome: string | null;
  recordId: string;
  rejectedReasonCode: string | null;
  rejectedReasonType: string | null;
  rejectedReasonDescription: string | null;
  actionTimestamp: string;
};

type MainRowWithSubRows = {
  jobId: string;
  filename: string;
  userAction: string;
  falloutStatus: string;
  outcome: string | null;
  assignedTo: string;
  actionTimestamp: string;
  rejectedReasonType: string | null;
  rejectedReasonDescription: string | null;
  rejectedReasonCode: string | null;
  subRows: SubRow[];
};
const useAuditWithSubRowsQuery = ({ isDownloadClicked }) => {
  const {
    data: auditData,
    isLoading: auditIsLoading,
    error: auditError,
  } = useAuditQuery();

  const { detailsData, detailsIsLoading, detailsError } = useQueries({
    queries: (auditData || []).map((audit) => ({
      queryKey: ['auditJobID', audit.jobId],
      queryFn: () => getAuditTrailByJobID(audit.jobId),
      enabled: isDownloadClicked,
    })),
    combine: (results) => {
      return {
        detailsData: results.map((result) => result.data),
        detailsIsLoading: results.some((result) => result.isLoading),
        detailsError: results.find((result) => result.error)?.error,
      };
    },
  });

  const combinedData = useMemo(() => {
    if (!auditData || !detailsData || detailsIsLoading) {
      return [];
    }

    return auditData.map((audit, index) => ({
      ...audit,
      subRows: detailsData[index] || [],
    }));
  }, [auditData, detailsData, detailsIsLoading]);

  return {
    data: combinedData as unknown as MainRowWithSubRows[],
    isLoading: auditIsLoading || detailsIsLoading,
    error: auditError || detailsError,
  };
};
export {
  useJobIDQuery,
  useFalloutQuery,
  useAuditQuery,
  useAuditJobIDQuery,
  useDetailDownloadQuery,
  useAuditWithSubRowsQuery,
};
export type { AuditDataTableData, AuditDataTableData2, Outcome };

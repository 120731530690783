import { config } from '@abyss/web/tools/config';

import { graphConfig, loginRequest } from '@src/authConfig';
import { msalInstance } from '@src/browser';
import axios, { AxiosResponse } from 'axios';

const apiURL = config('API_BASE_URL');

// Get Access Token
const getTokens = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    return response.accessToken;
  } catch (error) {
    throw error;
  }
};

// SSO
const callMsGraph = async () => {
  try {
    const accessToken = await getTokens();
    const response = await axios.get(graphConfig.graphMeEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create Authenticated Axios Instance
const createAuthenticatedAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });

  // instance.interceptors.request.use(
  //   async (configuration) => {
  //     const accessToken = await getTokens();
  //     // configuration.headers.Authorization = `Bearer ${accessToken}`;
  //     return configuration;
  //   },
  //   (error) => Promise.reject(error)
  // );

  return instance;
};

const falloutIdURL =
  'https://dear-lab.uhg.com/fallout-service/getFalloutRecordDetails';

const falloutURL =
  'https://dear-lab.uhg.com/fallout-service/getFalloutJobDetails';

const auditURL =
  'https://dear-lab.uhg.com/fallout-service/getFalloutJobAuditDetails';

const auditIdURL =
  ' https://dear-lab.uhg.com/fallout-service/getFalloutAuditDetails';
const updateURL =
  'https://dear-lab.uhg.com/fallout-service/updateFalloutJobDetail';
const DetailDownlaodURL =
  'https://dear-lab.uhg.com/fallout-service/getFalloutFileUrl';

type MutationVariables = {
  falloutStatus: string;
  jobId: string | undefined; // Allow jobId to be string or undefined
  assignTo?: string;
  userAction: string;
  recordId?: string;
  rejectedReasonCode?: string;
  rejectedReasonType?: string;
  rejectedReasonDescription?: string;
};

type UpdateActionResponse = {
  success: boolean;
  message: string;
};

const postUpdateAction = async ({
  falloutStatus,
  jobId,
  assignTo,
  userAction,
  recordId,
  rejectedReasonCode,
  rejectedReasonType,
  rejectedReasonDescription,
}: MutationVariables): Promise<AxiosResponse<UpdateActionResponse>> => {
  const formData = new FormData();
  formData.append('falloutStatus', falloutStatus);
  if (jobId) formData.append('jobId', jobId);
  if (assignTo) formData.append('assignTo', assignTo);
  formData.append('userAction', userAction);
  if (recordId) formData.append('recordId', recordId);
  if (rejectedReasonCode)
    formData.append('rejectedReasonCode', rejectedReasonCode);
  if (rejectedReasonType)
    formData.append('rejectedReasonType', rejectedReasonType);
  if (rejectedReasonDescription)
    formData.append('rejectedReasonDescription', rejectedReasonDescription);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await axios.post(updateURL, formData, config);
  return response;
};
const getByJobID = async (jobID) => {
  const config = {
    method: 'get',
    url: `${falloutIdURL}?jobId=${jobID}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await axios(config);
  // Pass the entire config object
  const data = response?.data;
  return {
    length: data?.length || 0,
    data: data,
  };
};
const getJobIDs = async () => {
  const config = {
    method: 'get',
    url: falloutURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(config);

    const data = response?.data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const getAuditTrailJobIDs = async () => {
  const config = {
    method: 'get',
    url: auditURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(config);

    const data = response?.data;

    return data;
  } catch (error) {
    // Pass the entire config object
    console.error('Error fetching data:', error);
  }
};
// getAuditTrailJobIDs();
const getAuditTrailByJobID = async (jobID) => {
  const config = {
    method: 'get',
    url: `${auditIdURL}?jobId=${jobID}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await axios(config);

    if (response?.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error;
  }
};

const getDetailDownloadJobID = async (jobID: string, fileName: string) => {
  const config = {
    method: 'get',
    url: `${DetailDownlaodURL}?jobId=${jobID}&fileName=${fileName}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  callMsGraph,
  createAuthenticatedAxiosInstance,
  getTokens,
  getByJobID,
  getJobIDs,
  postUpdateAction,
  getAuditTrailJobIDs,
  getAuditTrailByJobID,
  getDetailDownloadJobID,
};

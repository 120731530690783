import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { CSSProperties } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const StyledPage = ({ ...props }) => {
  const CustomPage = styled(Page, {
    '&.react-pdf__Page': {
      margin: '1em 0',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
    },
  });
  return <CustomPage {...props} />;
};
type OnLoadSuccessParams = {
  numPages: number;
};

const Unauthorized = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [numberOfPages, setNumberOfPages] = React.useState<number>();

  const onLoadSuccess = ({ numPages }: OnLoadSuccessParams) => {
    return setNumberOfPages(numPages);
  };

  return (
    <React.Fragment>
      <Grid justify="center">
        <Grid.Col span="100%">
          <Heading textAlign="center" style={{ marginTop: '40vh' }}>
            You are not authorized to access this application
          </Heading>
        </Grid.Col>
        <Grid.Col>
          <Button
            onClick={() => {
              return setIsOpen(true);
            }}
          >
            Click here to view how to gain access
          </Button>
        </Grid.Col>
        <Modal
          title="Access Instructions"
          size="full"
          isOpen={isOpen}
          onClose={() => {
            return setIsOpen(false);
          }}
        >
          <Modal.Section>
            <Text
              css={
                { display: 'block' } as Partial<
                  Record<'abyss-text-root', CSSProperties>
                >
              }
            >
              Please review the document below to learn how to gain access to
              this application.
            </Text>
            <Text>
              If the PDF is not loading, click{' '}
              <a
                href="/UI_Access_Instructions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                here{' '}
              </a>
              to download it instead.
            </Text>
          </Modal.Section>
          <Modal.Section>
            <Document
              file="/UI_Access_Instructions.pdf"
              onLoadSuccess={onLoadSuccess}
            >
              {Array.from(new Array(numberOfPages), (el, index) => {
                return (
                  <StyledPage
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={1.8}
                  />
                );
              })}
            </Document>
          </Modal.Section>
        </Modal>
      </Grid>
    </React.Fragment>
  );
};

export { Unauthorized };

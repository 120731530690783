import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Alert } from '@abyss/web/ui/Alert';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Table } from '@abyss/web/ui/Table';
import { getAuditTrailByJobID } from '@src/api/apis';
import type { AuditDataTableData, AuditDataTableData2 } from '@src/api/queries';
import { useAuditQuery, useAuditWithSubRowsQuery } from '@src/api/queries';
import { EnumCommonInfo, EnumErrorHandlingInfo } from '@src/enums';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

type Row = {
  depth: number;
  original: AuditDataTableData;
};

type CellProps = {
  row: Row;
  value: string;
};
type MainRow = {
  id: number;
  jobId: string;
  falloutStatus: string;
  recordId: string;
  userAction: string;
  assignedTo: string;
  outcome: string | null;
  rejectedReasonCode: string | null;
  rejectedReasonType: string | null;
  rejectedReasonDescription: string | null;
  actionTimestamp: string;
};
type SubRow = {
  jobId: string;
  falloutStatus: string;
  userAction: string;
  assignedTo: string;
  recordId: string;

  outcome: string;
  rejectedReasonCode: string | null;
  rejectedReasonType: string | null;
  rejectedReasonDescription: string | null;
  actionTimestamp: string;
};

export const AuditTrail: React.FC = () => {
  const { data, isLoading, error, isError } = useAuditQuery();
  const [isDownloadClicked, setIsDownloadClicked] = useState<boolean>(false);

  const { data: data2, isLoading: j } = useAuditWithSubRowsQuery({
    isDownloadClicked,
  });

  const [expandedJobData, setExpandedJobData] = React.useState<object>({});

  const [expandedJobIds, setExpandedJobIds] = useState<string[]>([]);
  const convertToLocaleString = (value) => {
    if (value === '' || value === null || value === undefined) {
      return null;
    }
    const newDateTime = new Date(value).toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    return newDateTime;
  };
  const recentTableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Job ID',
        accessor: 'jobId',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'File Name',
        accessor: 'filename',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Received Date and Time stamp',
        accessor: 'createdTimestamp',
        minWidth: 180,
        Cell: ({ row, value }: CellProps) => {
          return <>{convertToLocaleString(value)}</>;
        },
      },
      {
        Header: 'Release Date and Time Stamp',
        accessor: 'actionTimestamp',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          console.log(value);

          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {isSubRow
                ? convertToLocaleString(value || '')
                : convertToLocaleString(row?.original?.completedTimestamp)}
            </div>
          );
        },
      },
      {
        Header: 'Time Duration',
        accessor: 'timeDuration',
        minWidth: 180,
      },
      {
        Header: 'Action',
        accessor: 'userAction',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {isSubRow ? value : row?.original?.userAction}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'falloutStatus',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {isSubRow ? value : row?.original?.falloutStatus}
            </div>
          );
        },
      },
      {
        Header: 'Outcome',
        accessor: 'outcome',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div
              style={{
                color: isSubRow ? '#003C71' : '',
                whiteSpace: 'nowrap',
                overflow: 'auto',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Analyst/User',
        accessor: 'assignedTo',
        minWidth: 180,

        Cell: ({ row, value }: CellProps) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {isSubRow ? value : row?.original?.assignedTo}
            </div>
          );
        },
      },
    ];
  }, []);
  const processJobData = (
    jobData: AuditDataTableData2[],
    initialCreatedTimestamp: string
  ) => {
    const newjobData = Array.isArray(jobData)
      ? [...jobData].reverse()
      : jobData;

    let previousTimestamp =
      convertToLocaleString(initialCreatedTimestamp) || '';

    let totalDuration = 0;

    const updatedData = newjobData?.map((job) => {
      const currentTimestamp =
        convertToLocaleString(job?.actionTimestamp) || '';

      let timeDuration = job?.actionTimestamp
        ? new Date(currentTimestamp).getTime() -
          new Date(previousTimestamp).getTime()
        : 0;

      totalDuration += timeDuration;

      const updatedRow = {
        ...job,
        createdTimestamp: previousTimestamp || '',
        actionTimestamp: currentTimestamp,
        timeDuration: (timeDuration / 1000 / 60).toFixed(2), // Convert to minutes
      };

      if (job.actionTimestamp) {
        previousTimestamp = convertToLocaleString(job?.actionTimestamp) || '';
      }

      return updatedRow;
    });
    const totalDurationMins = (totalDuration / 1000 / 60).toFixed(2);

    const summaryRow = {
      createdTimestamp: 'Total_Duration',
      timeDuration: `${totalDurationMins} mins`,
    };
    if (newjobData?.[newjobData.length - 1]?.falloutStatus === 'REJECTED') {
      const lastJob = updatedData[updatedData.length - 1];
      lastJob.outcome = `${lastJob.rejectedReasonCode || ''}, ${
        lastJob.rejectedReasonType || ''
      }, ${lastJob.rejectedReasonDescription || ''}`;
    }
    return { ...updatedData, summaryRow };
  };

  const convertToCSV = (
    data: (MainRow | SubRow)[],
    recentTableColumns: { Header: string; accessor: string }[]
  ) => {
    const header = recentTableColumns.map((col) => col.Header).join(',');

    const rows = data.map((row) =>
      recentTableColumns
        .map((col) => {
          const value = row[col.accessor as keyof typeof row];

          if (
            (col.Header === 'Outcome' || col.Header === 'Analyst/User') &&
            typeof value === 'string'
          ) {
            try {
              const outcomeData = JSON.parse(value);

              const flattenObject = (obj, prefix = ''): string[] => {
                return Object.entries(obj).map(([key, val]) => {
                  if (typeof val === 'object' && val !== null) {
                    return `${prefix}${key}: { ${flattenObject(val, '')} }`;
                  }
                  return `${prefix}${key}: ${val}`;
                });
              };

              return `"${flattenObject(outcomeData).join('; ')}"`;
            } catch (e) {
              return `"${value.replace(/"/g, '""')}"`; // Wrap and escape value properly
            }
          }

          if (typeof value === 'object' && value !== null) {
            return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          }

          return typeof value === 'string'
            ? `"${value.replace(/"/g, '""')}"`
            : value;
        })
        .join(',')
    );

    return [header, ...rows].join('\n');
  };

  const generateCSV = (data2) => {
    const combinedData: (MainRow | SubRow)[] = [];

    data2.forEach((item) => {
      const { subRows, ...mainRow } = item;
      combinedData.push(mainRow);
      if (subRows && subRows.length > 0) {
        const processedJobData = processJobData(
          subRows,
          mainRow.createdTimestamp
        ) as { summaryRow?: any };
        const processedJobDataArray = Array.isArray(processedJobData)
          ? processedJobData
          : Object.values(processedJobData).filter(
              (row) => row !== processedJobData?.summaryRow
            );

        processedJobDataArray.forEach((subRow) => {
          combinedData.push(subRow);
        });

        // Add the summary row if it exists
        if (processedJobData.summaryRow) {
          combinedData.push(processedJobData?.summaryRow);
        }
      }
    });
    const csvContent = convertToCSV(combinedData, recentTableColumns);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const customDownloadOption = {
    title: 'Download Full Dataset (CSV)',
    icon: <IconSymbol icon="download_done" />,
    onClick: () => {
      setIsDownloadClicked(true);
      if (isDownloadClicked && data2) {
        generateCSV(data2);
      }
    },
  };

  const renderRowSubComponent = useCallback(
    (row) => {
      const id = row?.original?.jobId;
      const initialCreatedTimestamp = row?.original?.createdTimestamp;

      setExpandedJobIds((prevIds) => {
        if (prevIds.includes(id)) return prevIds;
        return [...prevIds, id];
      });

      const jobData = expandedJobData[id];

      if (!jobData) {
        return <>Loading...</>;
      }
      if (jobData instanceof AxiosError) {
        return (
          <React.Fragment>
            <Alert
              title="Error"
              variant="error"
              isVisible={true}
              css={{
                'abyss-alert-root': {
                  padding: '10px',
                  paddingLeft: '44px',
                  width: '100%',
                  boxSizing: 'border-box',
                },
              }}
            >
              {(jobData as AxiosError)?.code ===
              EnumErrorHandlingInfo.ERR_NETWORK
                ? EnumErrorHandlingInfo.ERROR +
                  EnumCommonInfo.COLON +
                  ' ' +
                  EnumErrorHandlingInfo.API_FAILURE
                : EnumErrorHandlingInfo.ERROR +
                  EnumCommonInfo.COLON +
                  ' ' +
                  EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR}
            </Alert>
          </React.Fragment>
        );
      }
      const processedJobData = processJobData(jobData, initialCreatedTimestamp);

      const clonedRow = Object.entries(processedJobData)
        .filter(([key]) => key !== 'summaryRow')
        .map(([, value]) => value);

      clonedRow.push(processedJobData?.summaryRow);

      return (
        <React.Fragment>
          <Table.Container
            title="Job Details"
            css={{
              'abyss-table-root': { tableLayout: 'fixed' },
            }}
          >
            <Table.TableBody>
              {clonedRow.map((jobRow, index) => (
                <Table.Row
                  key={index}
                  css={{
                    'abyss-table-row': { display: 'flex', width: '100%' },
                  }}
                >
                  <Table.Cell
                    css={{
                      'abyss-table-cell': {
                        width: 44,
                        minWidth: 44,
                        maxWidth: 44,
                      },
                    }}
                  >
                    <IconSymbol
                      icon="remove"
                      size="24px"
                      css={{
                        'abyss-icon-symbol': {
                          overflow: 'hidden',
                        },
                      }}
                    />
                  </Table.Cell>
                  {recentTableColumns.map((column, colIndex) => (
                    <Table.Cell
                      key={colIndex}
                      css={{
                        'abyss-table-cell': {
                          width: 'var(--cell-width, 230px)',
                          color: '#003C71',
                          boxSizing: 'border-box',
                        },
                      }}
                    >
                      {jobRow[column.accessor as keyof typeof jobRow]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.TableBody>
          </Table.Container>
        </React.Fragment>
      );
    },
    [expandedJobData, expandedJobIds]
  );

  const recentTableData = useDataTable({
    initialColumns: recentTableColumns,
    noDataMessage: 'No results',
    showDownloadButton: isError ? false : true,
    showGlobalFilter: true,
    pageSizeDefault: 5,
    renderSubComponent: renderRowSubComponent,
    downloadButtonConfig: {
      removeFull: true,
      custom: customDownloadOption,
      removeFiltered: true,
      fullCsvFileName: 'Audit_Trail_Data.csv',
    },
    pageSizeOptions: [5, 10, 25],
    highlightRowOnHover: true,
  });

  useEffect(() => {
    if (data) {
      recentTableData.setData(data);
    }
  }, [data]);

  useEffect(() => {
    const fetchJobData = async () => {
      const jobIdsToFetch = expandedJobIds.filter(
        (jobId) =>
          !expandedJobData[jobId] ||
          expandedJobData[jobId]?.code === 'ERR_NETWORK' ||
          expandedJobData[jobId]?.response?.status === 500 ||
          expandedJobData[jobId]?.response?.status === 400 ||
          expandedJobData[jobId]?.response?.status === 404
      );

      if (jobIdsToFetch.length === 0) return;

      const newData = await Promise.all(
        jobIdsToFetch.map(async (jobId) => {
          try {
            const jobData = await getAuditTrailByJobID(jobId);
            return { jobId, jobData, error: null }; // Store data
          } catch (error) {
            return { jobId, jobData: null, error }; // Store error
          }
        })
      );

      setExpandedJobData((prevData) => {
        const newDataObj = { ...prevData };
        newData.forEach(({ jobId, jobData, error }) => {
          newDataObj[jobId] = error ? error : jobData;
        });
        return newDataObj;
      });
    };
    fetchJobData();
    const handleOnline = () => {
      fetchJobData();
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [expandedJobIds]);

  useEffect(() => {
    const updateCellWidth = () => {
      const zoomLevel = window.devicePixelRatio;
      const baseWidth = 1600;

      const newWidth = baseWidth / zoomLevel;

      document.documentElement.style.setProperty(
        '--cell-width',
        `${newWidth}px`
      );
    };

    window.addEventListener('resize', updateCellWidth);
    updateCellWidth();

    return () => {
      window.removeEventListener('resize', updateCellWidth);
    };
  }, []);

  return (
    <React.Fragment>
      <Card collapse header="Closed Submissions">
        <Card.Section
          css={{
            'abyss-card-section': {
              paddingLeft: '10px',
              paddingRight: '10px',
            },
          }}
        >
          <Alert
            title={EnumErrorHandlingInfo.ERROR}
            variant={EnumErrorHandlingInfo.ERROR_SMALL}
            isVisible={isError}
          >
            {(error as any)?.code === EnumErrorHandlingInfo.ERR_NETWORK
              ? EnumErrorHandlingInfo.ERROR +
                EnumCommonInfo.COLON +
                ' ' +
                EnumErrorHandlingInfo.API_FAILURE
              : EnumErrorHandlingInfo.ERROR +
                EnumCommonInfo.COLON +
                ' ' +
                EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR}
          </Alert>
          <LoadingOverlay
            loadingTitle="Please Wait"
            loadingMessage="Retrieving Information."
            statusTitle="Status update"
            statusMessage="Sample message..."
            isDismissable
            ariaLoadingLabel="Example loading aria label"
            isLoading={isLoading}
          >
            <DataTable
              title="Recent Submissions"
              tableState={recentTableData}
              data-testid="datatable-recent-submissions"
              css={{
                'abyss-data-table-subheader-lower-container': { order: 1 },
                'abyss-data-table-subheader-top-container': {
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: '20px',
                },
                'abyss-data-table-pagination-bottom-root': {
                  display: 'none',
                },
                // 'abyss-data-table-pagination-top-root': {
                //   display: 'contents',
                // },
                'abyss-data-table-header': { display: 'none' },
              }}
            />
          </LoadingOverlay>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
};

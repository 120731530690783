import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useForm } from '@abyss/web/hooks/useForm';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Router } from '@abyss/web/ui/Router';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useActionMutation } from '@src/api/mutation';
import {
  getFlnStorage,
  removeFromLocalStorage,
  setFlnStorage,
} from '@src/Utilities';
import React, { useEffect, useState, ChangeEvent, CSSProperties } from 'react';
import { MemberInfo } from './MemberInfo';
import {
  EnumColumnInfo,
  EnumErrorHandlingInfo,
  EnumCommonInfo,
} from '@src/enums';
import { Alert } from '@abyss/web/ui/Alert';
import { AxiosError } from 'axios';

type RejectFormData = {
  rejectCode: string;
  rejectReason: string;
  rejectDescription: string;
};

type LocationState = {
  state: {
    filename: string;
  };
};
export const Detail: React.FC = () => {
  const alertStyles: CSSProperties = {
    paddingBottom: '10px',
  };
  const { getLocation } = useRouter();
  const location = getLocation() as LocationState;
  const fileName = location?.state?.filename;
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [recordId, setRecordId] = useState<string>('');
  const [isJobError, setIsJobError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  const handleDataFromChild = (data: {
    falloutStatus: string | undefined;
    recordId: string | null;
  }) => {
    setCurrentStatus(data?.falloutStatus || '');
    setRecordId(data?.recordId || '');
  };
  const {
    mutateAsync: actionMutation,
    isError: isError,
    error: error,
  } = useActionMutation();
  const { getRouteParams, navigate } = useRouter();
  const { jobId } = getRouteParams();

  const modal = useOverlay('Reject Reason');

  const [released, setReleased] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onHold = EnumCommonInfo.ON_HOLD;
  const rejected = EnumCommonInfo.REJECTED;
  const unHold = EnumCommonInfo.UN_HOLD;
  const completed = EnumCommonInfo.COMPLETED;
  const inProgress = EnumCommonInfo.IN_PROGRESS;

  const onSubmit = async () => {
    if (!recordId) {
      setIsErrorVisible(true);
      setErrorMessage(
        EnumErrorHandlingInfo.ERROR +
          EnumCommonInfo.COLON +
          ' ' +
          EnumErrorHandlingInfo.SELECT_MEMBER_ID
      );
      return;
    }
    try {
      if (jobId) {
        await actionMutation({
          falloutStatus: completed,
          jobId: jobId,
          recordId: recordId,
          userAction: completed,
        });
        removeFromLocalStorage(jobId);
        navigate('/internal/home');
      }
    } catch (error) {
      setIsErrorVisible(true);
      setErrorMessage(
        (error as AxiosError)?.code === EnumErrorHandlingInfo.ERR_NETWORK
          ? EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.API_FAILURE
          : EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR_UPDATE
      );
    }
  };

  const onRejectClick = () => {
    modal.open();
  };

  const onHoldClick = async () => {
    try {
      await actionMutation({
        falloutStatus:
          currentStatus === EnumCommonInfo.SLA_RISK ||
          currentStatus === EnumCommonInfo.DELAYED
            ? currentStatus
            : onHold,
        jobId: jobId,
        userAction: onHold,
      });
      setReleased(true);
    } catch (error) {
      setIsErrorVisible(true);
      setReleased(false);
      setErrorMessage(
        (error as AxiosError)?.code === EnumErrorHandlingInfo.ERR_NETWORK
          ? EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.API_FAILURE
          : EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR_UPDATE
      );
    }
  };
  const onRelease = async () => {
    try {
      await actionMutation({
        falloutStatus:
          currentStatus === EnumCommonInfo.SLA_RISK ||
          currentStatus === EnumCommonInfo.DELAYED
            ? currentStatus
            : inProgress,
        jobId: jobId,
        userAction: unHold,
      });
      setReleased(false);
    } catch (error) {
      setIsErrorVisible(true);
      setReleased(false);
      setErrorMessage(
        (error as AxiosError)?.code === EnumErrorHandlingInfo.ERR_NETWORK
          ? EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.API_FAILURE
          : EnumErrorHandlingInfo.ERROR +
              EnumCommonInfo.COLON +
              ' ' +
              EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR_UPDATE
      );
    }
  };

  const rejectForm = useForm<RejectFormData>();
  const rejectCodes = rejectForm.watch(EnumColumnInfo.REJECT_CODE);

  const rejectReason = rejectForm.watch(EnumColumnInfo.REJECT_REASON);
  const rejectDescription = rejectForm.watch(EnumCommonInfo.REJECT_DESCRIPTION);

  const onRejectSubmit = async (data: RejectFormData) => {
    let hasError = false;
    if (!data.rejectReason) {
      rejectForm.setError(EnumColumnInfo.REJECT_REASON, {
        type: EnumCommonInfo.REQUIRED,
        message:
          EnumErrorHandlingInfo.ERROR +
          EnumCommonInfo.COLON +
          EnumErrorHandlingInfo.REJECT_REASON_ERROR,
      });
      hasError = true;
    }
    if (!data.rejectCode) {
      rejectForm.setError(EnumColumnInfo.REJECT_CODE, {
        type: EnumCommonInfo.REQUIRED,
        message:
          EnumErrorHandlingInfo.ERROR +
          EnumCommonInfo.COLON +
          EnumErrorHandlingInfo.REJECT_CODE_ERROR,
      });
      hasError = true;
    }
    if (!data.rejectDescription) {
      rejectForm.setError(EnumCommonInfo.REJECT_DESCRIPTION, {
        type: EnumCommonInfo.REQUIRED,
        message:
          EnumErrorHandlingInfo.ERROR +
          EnumCommonInfo.COLON +
          EnumErrorHandlingInfo.REJECT_DESCRIPTION_ERROR,
      }),
        (hasError = true);
    }

    if (
      data.rejectCode !== '' &&
      data.rejectReason !== '' &&
      data.rejectDescription !== '' &&
      !hasError
    ) {
      try {
        await actionMutation({
          falloutStatus: rejected,
          jobId: jobId,
          rejectedReasonType: rejectReason,
          rejectedReasonCode: rejectCodes,
          rejectedReasonDescription: rejectDescription,
          userAction: rejected,
        });
        navigate('/internal/home');
        modal.close();
      } catch (error) {
        setIsModalErrorVisible(true);
        setModalErrorMessage(
          (error as AxiosError)?.code === EnumErrorHandlingInfo.ERR_NETWORK
            ? EnumErrorHandlingInfo.ERROR +
                EnumCommonInfo.COLON +
                ' ' +
                EnumErrorHandlingInfo.API_FAILURE
            : EnumErrorHandlingInfo.ERROR +
                EnumCommonInfo.COLON +
                ' ' +
                EnumErrorHandlingInfo.INTERNAL_SERVER_ERROR_UPDATE
        );
      }
    }
  };

  const onRejectCancel = (data) => {
    rejectForm.clearErrors();
    rejectForm.reset();
    modal.close();
  };

  const data: RejectFormData[] = [
    {
      rejectCode: '',
      rejectReason: '',
      rejectDescription: '',
    },
  ];
  const rejectReasonsMap = {
    'R001 - MNF': [
      {
        value: EnumCommonInfo.DATE_NOT_WITHIN_RANGE,
        label: EnumCommonInfo.DATE_NOT_WITHIN_RANGE,
      },
    ],
    'R002 - MOS': [
      {
        value: EnumCommonInfo.SOURCE_SYSTEM_NOT_EQUAL_CDB,
        label: EnumCommonInfo.SOURCE_SYSTEM_NOT_EQUAL_CDB,
      },
      {
        value: EnumCommonInfo.ENROLLEE_SOURCE_CODE_NOT_IN,
        label: EnumCommonInfo.ENROLLEE_SOURCE_CODE_NOT_IN,
      },
      {
        value: EnumCommonInfo.COVERAGE_TYPE_NOT_EQUAL_M,
        label: EnumCommonInfo.COVERAGE_TYPE_NOT_EQUAL_M,
      },
    ],
  };
  const rejectColumns = React.useMemo(
    () => [
      {
        Header: EnumColumnInfo.REJECT_CODE_LABEL,
        accessor: EnumColumnInfo.REJECT_CODE,
        minWidth: 180,
        Cell: ({ value, cellActions, row }) => {
          return (
            <SelectInput
              placeholder={EnumCommonInfo.REJECT_CODE_PLACEHOLDER}
              hideLabel
              options={Object.keys(rejectReasonsMap).map((code) => ({
                value: code,
                label: code,
              }))}
              model={EnumColumnInfo.REJECT_CODE}
              css={{
                'abyss-select-input-root': {
                  padding: '10px',
                },
              }}
              onChange={(value) => {
                cellActions.modifyRow(row, {
                  rejectCode: value as string,
                });
              }}
              label={''}
            />
          );
        },
      },
      {
        Header: EnumColumnInfo.REJECT_REASON_MODAL_TITLE,
        accessor: EnumColumnInfo.REJECT_REASON,
        minWidth: 250,
        Cell: ({ value, cellActions, row }) => {
          const rejectCode = row?.original?.rejectCode;
          const reasonOptions = rejectReasonsMap[rejectCode] || [];
          return (
            <SelectInput
              placeholder={EnumCommonInfo.REJECT_REASON_PLACEHOLDER}
              hideLabel
              options={reasonOptions}
              model={EnumColumnInfo.REJECT_REASON}
              css={{
                'abyss-select-input-root': {
                  padding: '10px',
                },
              }}
              onChange={(val) => {
                cellActions.modifyRow(row, { rejectReason: val });
              }}
              label={''}
            />
          );
        },
      },
      {
        Header: EnumColumnInfo.REJECT_DESCRIPTION,
        accessor: EnumCommonInfo.REJECT_DESCRIPTION,
        minWidth: 250,
        Cell: ({ cellActions, row }) => {
          return (
            <TextInput
              placeholder={EnumCommonInfo.REJECT_DESCRIPTION_PLACEHOLDER}
              model={EnumCommonInfo.REJECT_DESCRIPTION}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                cellActions.modifyRow(row.original, {
                  rejectDescription: event.target.value,
                });
              }}
              label={''}
              css={{
                'abyss-text-input-root': {
                  paddingBottom: '4px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            />
          );
        },
      },
    ],
    []
  );

  const rejectDataTableProps = useDataTable({
    initialColumns: rejectColumns,
    initialData: data,
    showPagination: false,
    noDataMessage: EnumCommonInfo.NO_RESULTS,
  });
  useEffect(() => {
    if (jobId) {
      const savedReleasedState = getFlnStorage(jobId).released;
      if (typeof savedReleasedState === 'boolean') {
        setReleased(savedReleasedState);
      }
    }
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      setFlnStorage(jobId, EnumCommonInfo.RELEASED, released);
    }
  }, [released, jobId]);

  useEffect(() => {
    if (recordId) {
      setIsErrorVisible(false);
      setErrorMessage('');
    }
  }, [recordId]);

  return (
    <React.Fragment>
      <Alert
        title={EnumErrorHandlingInfo.ERROR}
        variant={EnumErrorHandlingInfo.ERROR_SMALL}
        isVisible={isErrorVisible}
        css={{ 'abyss-alert-root': alertStyles }}
      >
        {errorMessage}
      </Alert>
      <Router.MetaTags title={EnumCommonInfo.EDIT} />
      <React.Fragment>
        <Card collapse header={EnumCommonInfo.EDIT}>
          <Grid>
            <Grid.Col
              span={{ xs: 12 }}
              style={{
                // padding: '40px',
                overflow: 'hidden',
              }}
            >
              <MemberInfo
                sendDataToParent={handleDataFromChild}
                fileName={fileName}
                setJobError={setIsJobError}
              />
            </Grid.Col>
          </Grid>
          <Grid style={{ float: 'right', padding: '20px' }}>
            <Grid.Col>
              <Button
                size="$lg"
                onClick={onRejectClick}
                style={{
                  width: '120px',
                }}
                aria-haspopup="dialog"
                isDisabled={released || isJobError}
              >
                Reject
              </Button>
            </Grid.Col>{' '}
            <Grid.Col>
              {released ? (
                <Button
                  size="$lg"
                  onClick={onRelease}
                  style={{
                    width: '120px',
                  }}
                  isDisabled={isJobError}
                >
                  UnHold
                </Button>
              ) : (
                <Button
                  size="$lg"
                  onClick={onHoldClick}
                  style={{
                    width: '120px',
                  }}
                  isDisabled={isJobError}
                >
                  Hold
                </Button>
              )}
            </Grid.Col>{' '}
            <Grid.Col>
              <Button
                onClick={onSubmit}
                size="$lg"
                style={{
                  width: '120px',
                }}
                isDisabled={released || isJobError}
              >
                Submit
              </Button>
            </Grid.Col>{' '}
          </Grid>
        </Card>

        <FormProvider
          state={rejectForm}
          onSubmit={onRejectSubmit}
          autoComplete="on"
        >
          <Modal
            title={EnumColumnInfo.REJECT_REASON_MODAL_TITLE}
            model={EnumColumnInfo.REJECT_REASON_MODAL_TITLE}
            isOpen={isOpen}
            closeOnClickOutside={false}
            onClose={() => setIsOpen(false)}
          >
            <Modal.Section>
              {isModalErrorVisible && (
                <Alert
                  title={EnumErrorHandlingInfo.ERROR}
                  variant={EnumErrorHandlingInfo.ERROR_SMALL}
                  isVisible={isModalErrorVisible}
                  css={{ 'abyss-alert-root': alertStyles }}
                >
                  {modalErrorMessage}
                </Alert>
              )}
              <DataTable
                tableState={rejectDataTableProps}
                title=""
                css={{
                  'abyss-data-table-header': {
                    display: 'none',
                    paddingTop: '20px',
                  },
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                }}
              />
              <Layout.Space />
              <Layout.Group alignLayout="right" style={{ marginTop: '20px' }}>
                <Button type="reset" variant="outline" onClick={onRejectCancel}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    rejectForm.handleSubmit(onRejectSubmit)();
                    if (rejectForm.formState.isValid) {
                      modal.close();
                    }
                  }}
                >
                  Submit
                </Button>
              </Layout.Group>
            </Modal.Section>
          </Modal>
        </FormProvider>
      </React.Fragment>
    </React.Fragment>
  );
};

import { SSOAuthContext } from '@src/context/AuthProvider';
import { useContext } from 'react';

const useSSOAuth = () => {
  const context = useContext(SSOAuthContext);
  if (context === undefined) {
    throw new Error('useSSOAuth must be used within an AuthProvider');
  }
  return context;
};

export { useSSOAuth };
